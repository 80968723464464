import { NormalizeString } from "../helpers/stringHelpers";
import { eProductTag, Product } from "../models/productModel";

export const FeaturedProducts = (products: Product[]) => {
  return products
    .filter((x) => x.featured)
    .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
};

export const SearchProducts = (products: Product[], searchText: string) => {
  if (searchText === "") {
    return [];
  }

  searchText = NormalizeString(searchText);

  let results = products.filter((x) => {
    var name = NormalizeString(x.name);

    if (name.includes(searchText)) return true;

    var rValue = x.searchTerms?.some((e) => {
      return NormalizeString(e).includes(searchText);
    });

    return rValue;
  });

  return results as Product[];
};
