interface LogoProps {
  height?: string;
}

const Logo = ({ height = "40px" }: LogoProps) => {
  return (
    <div>
      <img
        src="/img/logo-nube.svg"
        alt="Prontonube logo"
        style={{ height: height, width: "auto" }}
      />
      <img
        style={{ height: height, width: "auto" }}
        src="/img/logo-title.svg"
        alt="Prontonube"
      />
    </div>
  );
};

export default Logo;
