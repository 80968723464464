import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

interface NavigateBackButtonProps {
  navigateToUrl?: string;
  onClick?: () => void;
}
const NavigateBackButton = ({
  navigateToUrl,
  onClick,
}: NavigateBackButtonProps) => {
  const navigate = useNavigate();

  return (
    <button
      className="btn btn-success btn-sm"
      style={{ maxHeight: "2rem", alignSelf: "center" }}
      onClick={() => {
        if (navigateToUrl) navigate(navigateToUrl);

        if (onClick) onClick();
      }}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </button>
  );
};

export default NavigateBackButton;
