import { eProductTag } from "../models/productModel";

export const GetTagText = (tag: eProductTag) => {
  switch (tag) {
    case eProductTag.Limited:
      return "Tiempo limitado";
    case eProductTag.New:
      return "Nuevo!";
    case eProductTag.Sale:
      return "Oferta!";
  }
};

export const GetTagColor = (tag: eProductTag) => {
  switch (tag) {
    case eProductTag.Limited:
      return "info";
    case eProductTag.New:
      return "success";
    case eProductTag.Sale:
      return "danger";
  }
};
