import { Button, Modal } from "react-bootstrap";
import { t } from "i18next";

interface ModalMessageProps {
    title: string;
    message: React.ReactNode;
    onOkClick?: () => void;
}

const ModalMessage = ({ title, message, onOkClick }: ModalMessageProps) => {
    return (
        <div className="modal show">
            <Modal show={true} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{message}</p>
                </Modal.Body>
                <Modal.Footer>
                    {onOkClick && (
                        <Button variant="primary" onClick={onOkClick}>
                            {t("ok")}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ModalMessage;
