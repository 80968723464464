import { Route, Routes, useNavigate } from "react-router-dom";
import SearchPage from "./pages/SearchPage/SearchPage";
import CategoryPage from "./pages/CategoryPage/CategoryPage";
import CheckoutPaymentPage from "./pages/Checkout/CheckoutPaymentPage";
import CheckoutCompletePage from "./pages/Checkout/CheckoutCompletePage";
import CheckoutInfoPage from "./pages/Checkout/CheckoutInfoPage";
import CheckoutCartPage from "./pages/Checkout/CheckoutCartPage";
import LoginOrSignUpPage from "./pages/Authentication/LoginOrSignUpPage";
import LoginPage from "./pages/Authentication/LoginPage";
import LogoutPage from "./pages/Authentication/LogoutPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import Header from "./components/Header/Header";
import { Button, Container } from "react-bootstrap";
import { ResetCart } from "./api/services/ShoppingCartServices";
import { t } from "i18next";

export const App = () => {
    return (
        <div id="app-div">
            <Header />
            <Container>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="search" element={<SearchPage />} />
                    <Route
                        path="category/:categoryName"
                        element={<CategoryPage />}
                    />
                    <Route path="category" element={<CategoryPage />} />
                    <Route
                        path="checkout-pay"
                        element={<CheckoutPaymentPage />}
                    />
                    <Route
                        path="checkout-info"
                        element={<CheckoutInfoPage />}
                    />
                    <Route
                        path="checkout-complete"
                        element={<CheckoutCompletePage />}
                    />
                    <Route path="checkout" element={<CheckoutCartPage />} />
                    <Route
                        path="login-or-signup"
                        element={<LoginOrSignUpPage />}
                    />
                    <Route path="login" element={<LoginPage />} />
                    <Route path="logout" element={<LogoutPage />} />
                    <Route path="reset" element={<ResetPage />} />
                </Routes>
            </Container>
        </div>
    );
};

function ResetPage() {
    const navigate = useNavigate();

    ResetCart();

    return (
        <div className="d-flex flex-column">
            {t("cart_reset")}
            <Button onClick={() => navigate("../")}>Continuar</Button>
        </div>
    );
}
