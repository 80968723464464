import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./styles/scss/stylesheet.scss";

import { ShoppingCartProvider } from "./api/context/ShoppingCartContext";
import { StoreContextProvider } from "./api/context/StoreContext";
import { SessionContextProvider } from "./api/context/SessionContext";
import { App } from "./app";
import ResetPassword from "./pages/Authentication/ResetPassword";

import "./locales/i18n";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    // <React.StrictMode>
    <div id="index-div">
        <BrowserRouter>
            <Routes>
                <Route
                    path="reset-password/:token"
                    element={<ResetPassword />}
                />
                <Route
                    path="*"
                    element={
                        <StoreContextProvider>
                            <SessionContextProvider>
                                <ShoppingCartProvider>
                                    <Routes>
                                        <Route
                                            path=":tenantCode/*"
                                            element={<App />}
                                        />
                                    </Routes>
                                </ShoppingCartProvider>
                            </SessionContextProvider>
                        </StoreContextProvider>
                    }
                />
            </Routes>
        </BrowserRouter>
    </div>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
