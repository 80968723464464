import { t } from "i18next";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Form } from "react-router-dom";

interface NotesEditorProps {
    note?: string;
    show: boolean;
    onSave: (notes?: string) => void;
    onCancel: () => void;
}

const NotesEditor: FunctionComponent<NotesEditorProps> = (
    props: NotesEditorProps
) => {
    const [note, setNote] = useState(props.note);

    const inputRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        // Set focus to textArea and caret to the last charater
        if (inputRef.current) {
            let searchInput: HTMLTextAreaElement =
                inputRef.current as HTMLTextAreaElement;
            var strLength = searchInput.value.length * 2;
            searchInput.focus();
            searchInput.setSelectionRange(strLength, strLength);
        }
    }, []);

    return (
        <Modal show={props.show} onHide={props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Notas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <textarea
                    ref={inputRef}
                    className="form-control"
                    placeholder={t("notes_placeholder")}
                    rows={3}
                    value={note}
                    onChange={(e) => setNote(e.currentTarget.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn-success"
                    onClick={() => props.onSave(note)}
                >
                    {t("save")}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default NotesEditor;
