import { FunctionComponent, useState } from "react";

import { useShoppingCart } from "../../api/context/ShoppingCartContext";
import { Product } from "../../api/models/productModel";

import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "../Common/LoadingButton";
import { t } from "i18next";

interface AddToCartProps {
    product: Product;
    disabled?: boolean;
}

//TODO: decreaseButton when it reaches the product interval, change icon to trash otherwise minus icon

const AddToCart: FunctionComponent<AddToCartProps> = ({
    product,
    disabled,
}: AddToCartProps) => {
    const isDisabled = !!disabled;

    const { getItemQuantity, increaseQuantity, decreaseQuantity } =
        useShoppingCart();

    const quantity = getItemQuantity(product.id);
    const [isLoading, setIsLoading] = useState(false);

    if (quantity === 0)
        return (
            <LoadingButton
                id="AddToCart-AddButton"
                disabled={isDisabled}
                isLoading={isLoading}
                onClick={async () => {
                    try {
                        setIsLoading(true);
                        await increaseQuantity(product.id, product.interval);
                    } catch (error) {
                        alert(error);
                    } finally {
                        setIsLoading(false);
                    }
                }}
            >
                {t("add_to_cart")}
            </LoadingButton>
        );
    return (
        <div id="AddToCart">
            <button
                id="AddToCart-Decrease"
                title={t("remove_from_cart")}
                disabled={isDisabled || isLoading}
                onClick={async () => {
                    try {
                        setIsLoading(true);
                        await decreaseQuantity(product.id, product.interval);
                    } catch (error) {
                        alert(error);
                    } finally {
                        setIsLoading(false);
                    }
                }}
            >
                <FontAwesomeIcon icon={faMinus} />
            </button>
            <div id="AddToCart-Quantity">
                <span>{quantity}</span>
                <span id="AddToCart-Quantity-UOM">{product.unitOfMeasure}</span>
            </div>

            <button
                id="AddToCart-Increase"
                title="Aumentar"
                disabled={isDisabled || isLoading}
                onClick={async () => {
                    try {
                        setIsLoading(true);
                        await increaseQuantity(product.id, product.interval);
                    } catch (error) {
                        alert(error);
                    } finally {
                        setIsLoading(false);
                    }
                }}
            >
                <FontAwesomeIcon icon={faPlus} />
            </button>
        </div>
    );
};

export default AddToCart;
