import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Product } from "../../api/models/productModel";

import ProductCard from "../../components/ProductCard/ProductCard";
import SearchEntry from "../../components/SearchEntry/SearchEntry";

import { SearchProducts } from "../../api/services/ProductServices";
import { useStoreContext } from "../../api/context/StoreContext";
import NavigateBackButton from "../../components/Navigation/NavigateBackButton";
import Footer from "../../components/Footer/Footer";
import { t } from "i18next";

interface SearchPageProps {}

const SearchPage: FunctionComponent<SearchPageProps> = () => {
    const { store } = useStoreContext();

    const [searchParams] = useSearchParams();
    const [searchText, setSearchText] = useState("");
    const [searchResult, setSearchResult] = useState<Product[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        async function init() {
            let t = searchParams.get("text");
            if (t) setSearchText(t);
        }
        init();
    }, []);

    function handleSearchTextChanged(text: string) {
        setSearchText(text);
        if (!store) return;

        let results = SearchProducts(store?.products, text);

        setSearchResult(results as Product[]);
    }

    return (
        <div id="SearchPage">
            <div id="SearchPage-header">
                <NavigateBackButton onClick={() => navigate(-1)} />
                <div
                    id="SearchPage-header--back"
                    onClick={() => navigate(-1)}
                ></div>
                <SearchEntry
                    value={searchText}
                    onChange={(e) => handleSearchTextChanged(e)}
                    autoFocus
                />
            </div>

            {searchText.length > 1 && searchResult.length < 1 && (
                <div className="no-records">{t("no_results")}</div>
            )}
            <div className="productsGrid">
                {searchResult.map((p) => (
                    <ProductCard product={p} key={p.id} />
                ))}
            </div>

            <Footer />
        </div>
    );
};

export default SearchPage;
