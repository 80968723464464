import { FunctionComponent } from "react";
import { FormatCurrency } from "../../api/helpers/stringHelpers";

import { Product } from "../../api/models/productModel";
import AddToCart from "../AddToCart/AddToCart";
import Badge from "../Badge/Badge";
import RibbonImage from "../Common/RibbonImage";

interface ProductCardProps {
  product: Product;
}

const ProductCard: FunctionComponent<ProductCardProps> = ({ product }) => {
  return (
    <div id="ProductCard">
      <div id="ProductCard--badge-placeholder">
        <Badge tag={product.tag} />
      </div>
      <div id="ProductCard--image-placeholder">
        <RibbonImage
          imageUrl={product.thumbUrl}
          ribbonText={product.outOfStock ? "AGOTADO" : ""}
        />
      </div>
      <p id="ProductCard--product-name">{product.name}</p>
      <div id="ProductCard--product-price-placeholder">
        {product.regularPrice > 0 && (
          <span id="ProductCard--product-regular-price">
            {FormatCurrency(product.regularPrice, true, true)}
          </span>
        )}
        <span id="ProductCard--product-price">
          {FormatCurrency(product.price, true)}
        </span>
        <span id="ProductCard--product-UOM">{product.unitOfMeasure}</span>
      </div>
      <div id="ProductCard--addToCart-placeholder">
        <AddToCart product={product} disabled={product.outOfStock} />
      </div>
    </div>
  );
};

export default ProductCard;
