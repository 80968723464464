import HeaderPlatform from "../../components/Header/HeaderPlatform";
import React, { useEffect, useRef, useState } from "react";
import { LoadingButton } from "../../components/Common/LoadingButton";
import { GetErrorMessage } from "../../api/helpers/apiHelper";
import { ResetPassword } from "../../api/services/UsersServices";
import { useNavigate, useParams } from "react-router-dom";
import ErrorModal from "../../components/Common/ErrorModal";
import { t } from "i18next";

const ResetPasswordPage: React.FC = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [showValidation, setShowValidation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [validationMessage, setValidationMessage] = useState("");

    const passwordInputRef = useRef<HTMLInputElement>(null);
    const confirmPasswordInputRef = useRef<HTMLInputElement>(null);

    const [passwordIsValid, setPasswordIsValid] = useState(true);

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const token = params.token;

    useEffect(() => {
        if (!token) {
            setErrorMessage(t("invalid_token"));
        }

        //TODO: Validar si el token no ha caducado y si esta caduco, sacarlo antes de pedir restablecer. Mas bien poder generar un nuevo request
    }, []);

    const Restablecer = async () => {
        setShowValidation(true);

        if (!password) {
            (passwordInputRef.current as HTMLElement).focus();
            setValidationMessage(t("type_your_password"));
            setPasswordIsValid(false);
            return false;
        }

        if (password.length < 6) {
            (passwordInputRef.current as HTMLElement).focus();
            setValidationMessage(t("password_length"));
            setPasswordIsValid(false);
            return false;
        }

        setPasswordIsValid(true);
        if (password !== confirmPassword) {
            (confirmPasswordInputRef.current as HTMLElement).focus();
            return false;
        }

        setShowValidation(false);

        try {
            setLoading(true);

            const response = await ResetPassword(token!, password);
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            alert(t("password_reset_success"));
            navigate("/");
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
            return false;
        } finally {
            setLoading(false);
        }
    };

    return (
        <div id="app-div">
            <HeaderPlatform />
            <div className="p-4">
                <h2>{t("reset_password")}</h2>

                <div className="form-floating my-3">
                    <input
                        type="password"
                        id="password"
                        value={password}
                        ref={passwordInputRef}
                        required
                        className={
                            "form-control " +
                            (showValidation && !passwordIsValid
                                ? " is-invalid"
                                : "")
                        }
                        placeholder={t("password")}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                    <label htmlFor="password">{t("password")}</label>
                    <div className="invalid-feedback">{validationMessage}</div>
                </div>
                <div className="form-floating mb-3">
                    <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        ref={confirmPasswordInputRef}
                        required
                        className={
                            "form-control " +
                            (showValidation && password !== confirmPassword
                                ? " is-invalid"
                                : "")
                        }
                        placeholder={t("confirm_password")}
                        onChange={(e) =>
                            setConfirmPassword(e.currentTarget.value)
                        }
                    />
                    <label htmlFor="confirmPassword">
                        {t("confirm_password")}
                    </label>
                    <div className="invalid-feedback">
                        {t("password_mismatch")}
                    </div>
                </div>

                <LoadingButton
                    isLoading={loading}
                    className="btn btn-lg btn-primary w-100"
                    loadingText=""
                    onClick={async () => {
                        await Restablecer();
                    }}
                >
                    {t("reset_password")}
                </LoadingButton>

                {errorMessage && (
                    <ErrorModal
                        errorMessage={errorMessage}
                        onOkClick={() => setErrorMessage("")}
                    />
                )}
            </div>
        </div>
    );
};
export default ResetPasswordPage;
