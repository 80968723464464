import React from "react";

interface HorizontalLaneProps {
  children?: React.ReactElement | React.ReactElement[] | string;
}

const HorizontalLane = ({ children }: HorizontalLaneProps) => {
  return <div className="horizontalLane">{children}</div>;
};

export default HorizontalLane;
