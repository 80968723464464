import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import { useShoppingCart } from "../../api/context/ShoppingCartContext";

import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStoreContext } from "../../api/context/StoreContext";

interface HeaderProps {
  name?: string;
  logoUrl?: string;
}

const Header: FunctionComponent<HeaderProps> = (props) => {
  const navigate = useNavigate();
  const { store } = useStoreContext();

  const cart = useShoppingCart();

  return (
    <div id="Header">
      <div id="Header-container">
        <div id="Header-bar">
          <div id="Header-brand">
            <img
              src={store?.logoUrl}
              alt="Store logo"
              onClick={() => navigate(".")}
            />
            <h1 onClick={() => navigate(".")}>{store?.name}</h1>
          </div>
          {cart.getCartNumberOfItems() > 0 && (
            <button
              id="Header-cart--button"
              onClick={() => navigate("checkout")}
            >
              <FontAwesomeIcon icon={faCartShopping} size="lg" />
              <div id="Header-cart--items">{cart.getCartNumberOfItems()}</div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
