import { Form } from "react-bootstrap";
import React, { useState } from "react";
import { LoadingButton } from "../../components/Common/LoadingButton";
import ErrorModal from "../../components/Common/ErrorModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ModalMessage from "../../components/Common/ModalMessage";
import { GetErrorMessage } from "../../api/helpers/apiHelper";
import { SendResetPasswordRequest } from "../../api/services/UsersServices";
import { t } from "i18next";

interface ResetPasswordRequestProps {
    onComplete: () => void;
    onCancel: () => void;
}

const ResetPassworRequest: React.FC<ResetPasswordRequestProps> = ({
    onComplete,
    onCancel,
}) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showCompletedMessage, setShowCompletedMessage] = useState(false);

    const [eMail, setEmail] = useState("");

    function ValidarCorreo() {
        if (!eMail) {
            setErrorMessage(t("type_your_email"));
            return false;
        }

        setErrorMessage("");
        return true;
    }

    const Restablecer = async () => {
        if (!ValidarCorreo()) return;

        try {
            setLoading(true);
            const response = await SendResetPasswordRequest(eMail);
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            setShowCompletedMessage(true);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
            return;
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
            <h1>{t("reset_password")}</h1>
            <Form className="p-3 w-75">
                <Form.Group>
                    <Form.Label>{t("email")}</Form.Label>
                    <Form.Control
                        type="email"
                        value={eMail}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                </Form.Group>
                <LoadingButton
                    isLoading={loading}
                    className="mt-3 w-100 btn btn-lg btn-primary"
                    onClick={Restablecer}
                    loadingText=" "
                >
                    {t("reset_password")}
                </LoadingButton>

                <button className={"mt-4 btn btn-link"} onClick={onCancel}>
                    <FontAwesomeIcon icon={faChevronLeft} /> {t("back")}
                </button>
            </Form>
            {showCompletedMessage && (
                <ModalMessage
                    title={t("attention")}
                    message={t("reset_password_email_sent")}
                    onOkClick={onComplete}
                />
            )}
            {errorMessage && (
                <ErrorModal
                    errorMessage={errorMessage}
                    onOkClick={() => setErrorMessage("")}
                />
            )}
        </div>
    );
};

export default ResetPassworRequest;
