import { FunctionComponent } from "react";
import { GetTagColor, GetTagText } from "../../api/services/TagServices";
import { eProductTag } from "../../api/models/productModel";
import BsBadge from "react-bootstrap/Badge";

interface BadgeProps {
  tag: eProductTag;
}

const Badge: FunctionComponent<BadgeProps> = ({ tag }) => {
  return <BsBadge bg={GetTagColor(tag)}>{GetTagText(tag)}</BsBadge>;
};

export default Badge;
