import { tAddress } from "../../components/Address/AddressInput";
import { Coupon } from "./couponModel";
import { tCustomer } from "./customerModel";
import { Product } from "./productModel";

export type ShoppingCart = {
    id: string;
    items: CartItem[];
    coupon?: Coupon;
    customer: tCustomer;
    deliveryType: eDeliveryType;
    deliveryAddress: tAddress;
    paymentMethod: ePaymentMethod;
    comments: string;
};

export type CartItem = {
    id: number;
    product: Product;
    quantity: number;
    amount: () => number;
    notes?: string;
};

export enum eDeliveryType {
    Pickup,
    Delivery,
}

export enum ePaymentMethod {
    Card,
    Cash,
    None,
}
