import { FunctionComponent, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddressInput, { tAddress } from "../../components/Address/AddressInput";
import NavigateBackButton from "../../components/Navigation/NavigateBackButton";
import { useSession } from "../../api/context/SessionContext";
import { useStoreContext } from "../../api/context/StoreContext";
import {
    CreateUserAccount,
    Login,
    ValidateCreateUserAccount,
} from "../../api/services/UsersServices";
import { LoadingButton } from "../../components/Common/LoadingButton";
import ErrorModal from "../../components/Common/ErrorModal";
import { GetErrorMessage } from "../../api/helpers/apiHelper";
import { t } from "i18next";

interface SignUpProps {
    clientId: string | null;
    onCancel: () => void;
}

const SignUp: FunctionComponent<SignUpProps> = (props: SignUpProps) => {
    const session = useSession();
    const { store } = useStoreContext();

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [search, setSearch] = useSearchParams();
    const navigate = useNavigate();

    const [showExtraInfo, setShowExtraInfo] = useState(false);

    const eMailInputRef = useRef<HTMLInputElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const confirmPasswordInputRef = useRef<HTMLInputElement>(null);
    const [showValidation, setShowValidation] = useState(false);
    const [eMail, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const nameInputRef = useRef<HTMLInputElement>(null);
    const [name, setName] = useState("");
    const ePhoneInputRef = useRef<HTMLInputElement>(null);
    const [phone, setPhone] = useState("");

    const [subscribeToEmail, setSubscribeToEmail] = useState(true);

    const [address, setAddress] = useState<tAddress>({
        street: "",
        outsideNumber: "",
        innerNumber: "",
        neighborhood: "",
        city: "",
        state: "",
        country: store.countryCode,
        zipCode: "",
        lat: 0,
        lng: 0,
    });

    const ValidarCuenta = async () => {
        setShowValidation(true);
        if (!eMail) {
            (eMailInputRef.current as HTMLElement).focus();
            return false;
        }
        if (!password) {
            (passwordInputRef.current as HTMLElement).focus();
            return false;
        }

        if (password !== confirmPassword) {
            (confirmPasswordInputRef.current as HTMLElement).focus();
            return false;
        }

        setShowValidation(false);

        try {
            const response = await ValidateCreateUserAccount(
                eMail,
                password,
                confirmPassword
            );

            if (
                !response.success &&
                (response.invalidProperty === "Email" ||
                    response.invalidProperty === "Password")
            ) {
                setErrorMessage(response.message);
                return false;
            }

            return true;
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
            return false;
        } finally {
            setLoading(false);
        }
    };

    const ValidarExtraInfo = () => {
        setShowValidation(true);
        if (!name) {
            (nameInputRef.current as HTMLElement).focus();
            return false;
        }
        if (!phone || phone.length < 10) {
            (ePhoneInputRef.current as HTMLElement).focus();
            return false;
        }
        setShowValidation(false);
        return true;
    };

    const CrearCuenta = async () => {
        if (!ValidarCuenta()) return;
        if (!ValidarExtraInfo()) return;

        try {
            setLoading(true);
            const response = await CreateUserAccount(
                eMail,
                password,
                confirmPassword,
                name,
                phone,
                address,
                subscribeToEmail
            );
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            await IniciarSesion();
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
            return;
        } finally {
            setLoading(false);
        }
    };

    const IniciarSesion = async () => {
        try {
            setLoading(true);
            const response = await Login(eMail, password);

            if (response.success && session.logIn(response.token)) {
                let returnPage = search.get("returnPage");
                if (!returnPage) returnPage = "./";
                navigate(returnPage);
                return;
            }

            // Si no pudo hacer login regresa a la pagina principal
            window.alert(t("login_failed") + " " + t("please_try_again_later"));
            navigate("./");
            return;
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
            return;
        } finally {
            setLoading(false);
        }
    };

    // Create username and password
    if (!showExtraInfo)
        return (
            <div className="mt-4  p-4">
                <div className="flex-title">
                    <NavigateBackButton onClick={props.onCancel} />
                    <h2>{t("create_new_account")}</h2>
                </div>
                <div className="form-floating mb-3">
                    <input
                        type="email"
                        id="email"
                        value={eMail}
                        required
                        className={
                            "form-control " +
                            (showValidation && !eMail ? " is-invalid" : "")
                        }
                        placeholder={t("email_placeholder")}
                        ref={eMailInputRef}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                    <label htmlFor="email">eMail</label>
                    <div className="invalid-feedback">
                        {t("type_your_email")}.
                    </div>
                </div>
                <div className="form-floating mb-3">
                    <input
                        type="password"
                        id="password"
                        value={password}
                        ref={passwordInputRef}
                        required
                        className={
                            "form-control " +
                            (showValidation && !password ? " is-invalid" : "")
                        }
                        placeholder={t("password")}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                    <label htmlFor="password">{t("password")}</label>
                    <div className="invalid-feedback">
                        {t("type_your_password")}.
                    </div>
                </div>
                <div className="form-floating mb-3">
                    <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        ref={confirmPasswordInputRef}
                        required
                        className={
                            "form-control " +
                            (showValidation && password !== confirmPassword
                                ? " is-invalid"
                                : "")
                        }
                        placeholder={t("confirm_password")}
                        onChange={(e) =>
                            setConfirmPassword(e.currentTarget.value)
                        }
                    />
                    <label htmlFor="confirmPassword">
                        {t("confirm_password")}
                    </label>
                    <div className="invalid-feedback">
                        {t("password_mismatch")}.
                    </div>
                </div>

                <div className="d-flex justify-content-end">
                    <LoadingButton
                        isLoading={loading}
                        className="btn btn-lg btn-primary"
                        onClick={async () => {
                            if (await ValidarCuenta()) setShowExtraInfo(true);
                        }}
                    >
                        {t("next")}
                    </LoadingButton>
                </div>

                {errorMessage && (
                    <ErrorModal
                        errorMessage={errorMessage}
                        onOkClick={() => setErrorMessage("")}
                    />
                )}
            </div>
        );

    // Additional account information
    return (
        <div className="mt-4  p-4">
            <div className="flex-title">
                <NavigateBackButton onClick={props.onCancel} />
                <h2>{t("create_new_account")}</h2>
            </div>
            <div className="form-floating mb-3">
                <div className="form-floating mb-3">
                    <input
                        type="email"
                        id="vEmail"
                        value={eMail}
                        disabled
                        className="form-control"
                    />
                    <label htmlFor="vEmail">eMail</label>
                </div>
            </div>
            <div className="form-floating mb-3">
                <input
                    type="text"
                    id="name"
                    value={name}
                    className={
                        "form-control " +
                        (showValidation && !name ? " is-invalid" : "")
                    }
                    placeholder={t("full_name")}
                    ref={nameInputRef}
                    required
                    onChange={(e) => setName(e.currentTarget.value)}
                />
                <label htmlFor="name">{t("full_name")}</label>
                <div className="invalid-feedback">
                    {t("full_name_placeholder")}.
                </div>
            </div>
            <div className="form-floating mb-3">
                <input
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    id="phone"
                    value={phone}
                    className={
                        "form-control " +
                        (showValidation &&
                            (!phone || phone.length < 10 ? " is-invalid" : ""))
                    }
                    placeholder={t("phone_placeholder")}
                    ref={ePhoneInputRef}
                    required
                    onChange={(e) => setPhone(e.currentTarget.value)}
                />
                <label htmlFor="name">{t("phone")}</label>
                <div className="invalid-feedback">{t("phone_invalid")}.</div>
            </div>

            <AddressInput
                address={address}
                onChange={(address) => {
                    setAddress(address);
                }}
            />

            <div className="form-check">
                <input
                    type="checkbox"
                    className="form-check-input"
                    id="checkSubscribe"
                    checked={subscribeToEmail}
                    onChange={(e) => setSubscribeToEmail(!subscribeToEmail)}
                />
                <label htmlFor="checkSubscribe">
                    {t("subscribe_to_email_newsletter")}
                </label>
            </div>

            <div className="d-flex justify-content-end mt-4 mb-4">
                <LoadingButton
                    isLoading={loading}
                    className="btn btn-success btn-lg w-100"
                    onClick={CrearCuenta}
                >
                    {t("continue")}
                </LoadingButton>
            </div>

            {errorMessage && (
                <ErrorModal
                    errorMessage={errorMessage}
                    onOkClick={() => setErrorMessage("")}
                />
            )}
        </div>
    );
};

export default SignUp;
