import { Spinner } from "react-bootstrap";

const LoadingComponent = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          padding: "16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" variant="primary" />
        <span style={{ marginLeft: "8px" }}>Cargando...</span>
      </div>
    </div>
  );
};

export default LoadingComponent;
