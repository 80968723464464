import { useNavigate } from "react-router-dom";
import { tShoppingCartContext } from "../../api/context/ShoppingCartContext";
import { FormatCurrency } from "../../api/helpers/stringHelpers";
import NotesEditor from "../../components/Notes/NotesEditor";
import { useState } from "react";
import AddToCart from "../../components/AddToCart/AddToCart";
import {
    Alert,
    FormControl,
    FormGroup,
    FormLabel,
    Modal,
} from "react-bootstrap";
import { LoadingButton } from "../../components/Common/LoadingButton";
import { t } from "i18next";

export const CheckoutCartEmpty = () => {
    const navigate = useNavigate();

    return (
        <div id="CheckoutCartPage">
            <div id="CheckoutCartPage-cart-empty">
                <div className="no-records">{t("cart_empty")}</div>
                <div id="CheckoutCartPage-cart-empty--button">
                    <button
                        className="btn-success"
                        onClick={() => navigate("../")}
                    >
                        {t("add_products")}
                    </button>
                </div>
            </div>
        </div>
    );
};

interface EmptyCartButtonProps {
    shoppingCart: tShoppingCartContext;
}
export const EmptyCartButton = ({ shoppingCart }: EmptyCartButtonProps) => {
    return (
        <button
            id="CheckoutCartPage-empty-cart--button"
            onClick={() => {
                if (window.confirm(t("clear_cart_confirmation")))
                    shoppingCart.emptyCart();
            }}
        >
            {t("clear_cart")}
        </button>
    );
};

interface ShoppingCartProductsListProps {
    shoppingCart: tShoppingCartContext;
}

export const ShoppingCartProductsList = ({
    shoppingCart,
}: ShoppingCartProductsListProps) => {
    const [editingNoteId, setEditingNoteId] = useState(0);

    return (
        <>
            {shoppingCart.cart.items.map((item) => (
                <div id="CheckoutCartPage-product" key={item.id}>
                    <img
                        id="CheckoutCartPage-product--img"
                        src={item.product.thumbUrl}
                        alt=""
                    />
                    <div id="CheckoutCartPage-product--info">
                        <div id="CheckoutCartPage-product--name">
                            {item.product.name}
                        </div>
                        <div id="CheckoutCartPage-product--amount-placeholder">
                            <span id="CheckoutCartPage-product--amount">
                                {FormatCurrency(item.amount(), true)}
                            </span>
                        </div>
                        <div id="CheckoutCartPage-product--actions">
                            <button
                                className="btn-primary-outline"
                                onClick={() =>
                                    setEditingNoteId(item.product.id)
                                }
                            >
                                {!item.notes && (
                                    <img
                                        src="/img/icons/notes-outline.png"
                                        alt="Sin notas"
                                    />
                                )}
                                {item.notes && (
                                    <img
                                        src="/img/icons/notes-filled.png"
                                        alt="Notas"
                                    />
                                )}
                            </button>
                            <div id="CheckoutCartPage-product--quantity">
                                <AddToCart product={item.product} />
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {editingNoteId > 0 && (
                <NotesEditor
                    note={shoppingCart.getItemNotes(editingNoteId)}
                    show={true}
                    onSave={(note) => {
                        shoppingCart.setItemNotes(editingNoteId, note);
                        setEditingNoteId(0);
                    }}
                    onCancel={() => setEditingNoteId(0)}
                />
            )}
        </>
    );
};

interface ApplyCouponProps {
    shoppingCart: tShoppingCartContext;
}

export const ApplyCoupon = ({ shoppingCart }: ApplyCouponProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [couponCode, setCouponCode] = useState<string>("");
    const [errorText, setErrorText] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);

    const applyCoupon = async () => {
        setErrorText("");

        if (!couponCode) {
            setErrorText(t("type_coupon_code"));
            return;
        }

        try {
            setLoading(true);
            const response = await shoppingCart.applyCoupon(couponCode);

            if (!response.success) {
                setErrorText(response.message!);
                return;
            }
        } catch (error: any) {
            console.log(error);
            if (error.message) setErrorText(error.message);
        } finally {
            setLoading(false);
        }
    };

    const removeCoupon = async () => {
        setErrorText("");

        if (!shoppingCart.getCouponApplied()) return;

        try {
            setLoading(true);
            await shoppingCart.removeCoupon();

            setCouponCode("");
        } catch (error: any) {
            console.log(error);
            if (error.message) setErrorText(error.message);
            else setErrorText(t("remove_coupon_error"));
        } finally {
            setLoading(false);
        }
    };
    return (
        <div>
            <button
                className="btn-primary w-100"
                onClick={() => setShowModal(true)}
            >
                {t("apply_coupon")}
            </button>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("apply_coupon")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <FormLabel>{t("coupon")}:</FormLabel>
                        <FormControl
                            type="text"
                            placeholder={t("coupon_placeholder")}
                            value={couponCode}
                            onChange={(e) => {
                                setCouponCode(e.currentTarget.value);
                                setErrorText("");
                            }}
                            isInvalid={couponCode === "" && errorText !== ""}
                        />
                    </FormGroup>
                    {shoppingCart.getCouponApplied() && (
                        <div className="pt-2 d-flex justify-content-between align-items-center">
                            <div>
                                {t("coupon_value_applied")}:{" "}
                                <span className="text-danger fw-bold">
                                    {FormatCurrency(
                                        shoppingCart.getDiscount(),
                                        true
                                    )}
                                </span>
                            </div>
                            <button
                                disabled={loading}
                                className="btn btn-outline-danger"
                                onClick={removeCoupon}
                            >
                                {t("remove_coupon")}
                            </button>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {errorText && (
                        <Alert className="w-100" variant="danger">
                            {errorText}
                        </Alert>
                    )}

                    <LoadingButton
                        isLoading={loading}
                        className="btn-success w-100"
                        onClick={applyCoupon}
                    >
                        {t("apply")}
                    </LoadingButton>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
