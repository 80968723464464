import { useRef, useState } from "react";
import { LoadingButton } from "../../components/Common/LoadingButton";
import { GetErrorMessage } from "../../api/helpers/apiHelper";
import { Login } from "../../api/services/UsersServices";
import { useSession } from "../../api/context/SessionContext";
import ErrorModal from "../../components/Common/ErrorModal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import React from "react";
import ResetPassworRequest from "./ResetPassworRequest";
import { t } from "i18next";

const LoginPage = () => {
    const params = useParams();
    const session = useSession();
    const navigate = useNavigate();
    const [search, setSearch] = useSearchParams();

    const [eMail, setEmail] = useState("");
    const eMailInputRef = useRef<HTMLInputElement>(null);

    const [password, setPassword] = useState("");
    const passwordInputRef = useRef<HTMLInputElement>(null);

    const [loading, setLoading] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [resetPasswordVisible, setResetPasswordVisible] = useState(false);

    const IniciarSesion = async () => {
        if (!ValidarCuenta()) return;

        try {
            setLoading(true);
            const response = await Login(eMail, password);
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            if (session.logIn(response.token)) {
                let returnPage = search.get("returnPage");
                if (!returnPage) returnPage = "/";
                navigate(returnPage);
            }
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
            return;
        } finally {
            setLoading(false);
        }
    };

    const ReturnToCaller = () => {
        let returnPage = search.get("returnPage");
        if (!returnPage)
            returnPage = params.tenantCode ? `/${params.tenantCode}/` : "/";
        navigate(returnPage);
    };

    const ValidarCuenta = () => {
        setShowValidation(true);
        if (!eMail) {
            (eMailInputRef.current as HTMLElement).focus();
            return false;
        }
        if (!password) {
            (passwordInputRef.current as HTMLElement).focus();
            return false;
        }

        setShowValidation(false);

        return true;
    };

    const ResetPasswordRequestCompleted = () => {
        setResetPasswordVisible(false);
        ReturnToCaller();
    };

    if (resetPasswordVisible) {
        return (
            <ResetPassworRequest
                onComplete={ResetPasswordRequestCompleted}
                onCancel={() => setResetPasswordVisible(false)}
            />
        );
    }

    return (
        <div className="mt-4 p-4">
            <div className="flex-title">
                <h2>{t("login")}</h2>
            </div>

            <div className="form-floating mb-3">
                <input
                    type="email"
                    id="email"
                    value={eMail}
                    required
                    className={
                        "form-control " +
                        (showValidation && !eMail ? " is-invalid" : "")
                    }
                    placeholder={t("email_placeholder")}
                    ref={eMailInputRef}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                />
                <label htmlFor="email">eMail</label>
                <div className="invalid-feedback">{t("type_your_email")}.</div>
            </div>
            <div className="form-floating mb-3">
                <input
                    type="password"
                    id="password"
                    value={password}
                    ref={passwordInputRef}
                    required
                    className={
                        "form-control " +
                        (showValidation && !password ? " is-invalid" : "")
                    }
                    placeholder={t("Password")}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                />
                <label htmlFor="password">{t("Password")}</label>
                <div className="invalid-feedback">
                    {t("type_your_password")}.
                </div>
            </div>

            <div>
                <div className="my-4 w-100 d-flex justify-content-center">
                    <a href="#" onClick={() => setResetPasswordVisible(true)}>
                        {t("forgot_password")}
                    </a>
                </div>

                <LoadingButton
                    isLoading={loading}
                    className="btn btn-lg btn-primary w-100"
                    onClick={IniciarSesion}
                >
                    {t("login")}
                </LoadingButton>
            </div>

            {errorMessage && (
                <ErrorModal
                    errorMessage={errorMessage}
                    onOkClick={() => setErrorMessage("")}
                />
            )}
        </div>
    );
};

export default LoginPage;
