import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { Category } from "../../api/models/categoryModel";

interface CategoriesGridProps {
  categories: Category[];
}

const CategoriesGrid: FunctionComponent<CategoriesGridProps> = ({
  categories,
}: CategoriesGridProps) => {
  const navigate = useNavigate();

  return (
    <div id="CategoriesGrid">
      {categories.map((category, i) => (
        <div
          id="CategoriesGrid-category"
          key={i}
          onClick={() =>
            navigate(`category/${category.name.toLocaleLowerCase()}`)
          }
        >
          <img src={category.imageUrl} alt="" /> <span>{category.name}</span>
        </div>
      ))}
    </div>
  );
};

export default CategoriesGrid;
