import { FunctionComponent } from "react";
import { Banner } from "../../api/models/bannerModel";
import BsCarousel from "react-bootstrap/Carousel";
import { useNavigate } from "react-router-dom";
interface CarouselProps {
    banners: Banner[];
}

const Carousel: FunctionComponent<CarouselProps> = (mainBanners) => {
    const navigate = useNavigate();
    const banners = mainBanners.banners.filter((x) => x.imageUrl !== "");
    if (banners.length === 0) return <div />;
    return (
        <div id="Carousel">
            <BsCarousel>
                {banners.map((x, i) => (
                    <BsCarousel.Item
                        key={i}
                        onClick={() => {
                            if (x.targetUrl) navigate(x.targetUrl);
                        }}
                    >
                        <BsCarousel.Caption>{x.caption}</BsCarousel.Caption>
                        <img
                            id="Carousel-img"
                            src={x.imageUrl}
                            alt={x.caption}
                        />
                    </BsCarousel.Item>
                ))}
            </BsCarousel>
        </div>
    );
};

export default Carousel;
