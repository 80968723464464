import { BuildUrl, CreateAxiosInstance } from "../helpers/apiHelper";
import { Store } from "../models/storeModel";
import { AxiosResponse, AxiosError } from "axios";
import { Banner } from "../models/bannerModel";

const axiosInstance = CreateAxiosInstance();

interface LocalDeliveryZone {
    zipCode: string;
}

export const GetStore = async (): Promise<Store> => {
    try {
        const response: AxiosResponse = await axiosInstance.get(
            BuildUrl("store")
        );
        const store = response.data.store as Store;

        // Carga los productos
        const productsResponse: AxiosResponse = await axiosInstance.get(
            BuildUrl("products")
        );
        store.products = productsResponse.data.products;

        // Carga las categorias
        const categoriesResponse: AxiosResponse = await axiosInstance.get(
            BuildUrl("product-categories")
        );
        store.categories = categoriesResponse.data.categories;

        // Carga las zonas de entrega locales
        const localDeliveryZonesResponse: AxiosResponse =
            await axiosInstance.get(BuildUrl("local-delivery-zones"));
        store.localDeliveryZones =
            localDeliveryZonesResponse.data.localDeliveryZones.map(
                (x: LocalDeliveryZone) => x.zipCode
            );

        // Arma la coleccion de banners
        store.banners = [];
        store.carouselUrls.forEach((url) => {
            const banner: Banner = {
                imageUrl: url,
                targetUrl: null,
            };
            store.banners.push(banner);
        });

        return store;
    } catch (error) {
        if (error instanceof AxiosError) {
            if (error.response) {
                if (error.response.status === 404) {
                    throw new Error("Store not found");
                }
            }
        }

        console.log("Error getting store", error);
        throw new Error("Error getting store " + error);
    }
};
