import React, { useEffect, ReactElement, FunctionComponent } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

interface MapComponentProps {
  markerLat: number | null;
  markerLng: number | null;
}

export const MapComponent: FunctionComponent<MapComponentProps> = ({
  markerLat,
  markerLng,
}) => {
  const [markers, setMarkers] = React.useState<
    google.maps.LatLng[] | google.maps.LatLngLiteral[]
  >([]);

  const [center, setCenter] = React.useState<
    google.maps.LatLng | google.maps.LatLngLiteral
  >({ lat: 20.67450994066495, lng: -103.3874117543881 });

  const onClick = (e: google.maps.MapMouseEvent) => {
    setMarkers([e.latLng!]);
  };

  useEffect(() => {
    if (markerLat && markerLng) {
      setMarkers([{ lat: markerLat, lng: markerLng }]);
      setCenter({ lat: markerLat!, lng: markerLng });
    }
  }, [markerLat, markerLng]);

  const render = (status: Status): ReactElement => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;

    const zoom = 17;

    return (
      <Map
        center={center}
        zoom={zoom}
        style={{ height: "300px", width: "100wh" }}
        onClick={onClick}
      >
        {markers.map((latLng, i) => (
          <Marker key={i} position={latLng} />
        ))}
      </Map>
    );
  };

  return (
    <Wrapper apiKey="AIzaSyCzrUxFDAd8NdZE3Jx072NhprvGxMprL9Q" render={render} />
  );
};

interface MapProps extends google.maps.MapOptions {
  style?: { [key: string]: string };
  children?: React.ReactNode;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
}

const Map: React.FC<MapProps> = ({
  center,
  zoom,
  style,
  onClick,
  onIdle,
  children,
  ...options
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center,
          zoom,
          fullscreenControl: true,
          streetViewControl: false,
          mapTypeControl: false,
          clickableIcons: false,
        })
      );
    }
  }, [ref, map]);

  useEffect(() => {
    map?.setCenter(center!);
  }, [center]);

  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);
  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};
