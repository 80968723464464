import { FunctionComponent, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import NavigateBackButton from "../../components/Navigation/NavigateBackButton";
import { useSession } from "../../api/context/SessionContext";
import SignUp from "./SignUp";
import { t } from "i18next";

const LoginOrSignUpPage: FunctionComponent = () => {
    const session = useSession();
    const navigate = useNavigate();
    const [search, setSearch] = useSearchParams();

    const [signUpId, setSignUpId] = useState<string | null>(null);
    const [showSignUp, setShowSignUp] = useState(false);

    function onSignUpByMail() {
        setSignUpId(null);
        setShowSignUp(true);
    }

    if (showSignUp)
        return (
            <SignUp clientId={signUpId} onCancel={() => setShowSignUp(false)} />
        );

    return (
        <div>
            <div className="flex-title">
                <NavigateBackButton navigateToUrl="/checkout" />
                <h2>{t("login_or_signup_title")}</h2>
            </div>

            <div className="p-4 text-center">
                <div>
                    <div className="border-bottom border-success py-4 my-4">
                        <button
                            className="btn-success w-100"
                            onClick={() => {
                                navigate(
                                    `../login?returnPage=${search.get(
                                        "returnPage"
                                    )}`
                                );
                                //TODO: Mostrar error
                            }}
                        >
                            {t("already_have_an_account")}
                        </button>
                    </div>
                    <div>
                        <button
                            className="btn-primary w-100 my-2"
                            onClick={() => onSignUpByMail()}
                        >
                            {t("create_new_account")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginOrSignUpPage;
