import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from "react";
import { tAddress } from "../../components/Address/AddressInput";
import {
    eDeliveryType,
    ePaymentMethod,
    ShoppingCart,
} from "../models/cartModel";
import { useStoreContext } from "./StoreContext";
import { tCustomer } from "../models/customerModel";
import { Coupon } from "../models/couponModel";
import {
    AddOrUpdateShoppingCartItem,
    ApplyCoupon,
    CreatePaymentIntent,
    CreateShoppingCart,
    GetShoppingCart,
    RemoveCoupon,
    RemoveShoppingCartItem,
    SetShoppingCartItemNotes,
} from "../services/ShoppingCartServices";

interface applyCouponResponse {
    success: boolean;
    message?: string;
}

export type tShoppingCartContext = {
    cart: ShoppingCart;

    setCustomer: (customer: tCustomer) => void;
    setAddress: (address: tAddress) => void;
    setDeliveryType: (deliveryType: eDeliveryType) => void;
    setPaymentMethod: (paymentMethod: ePaymentMethod) => void;
    setComments: (comments: string) => void;

    getCartNumberOfItems: () => number;

    getItemQuantity: (productId: number) => number;
    increaseQuantity: (productId: number, interval: number) => void;
    decreaseQuantity: (productId: number, interval: number) => void;
    removeFromCart: (productId: number) => void;

    emptyCart: () => void;
    getItemNotes: (productId: number) => string | undefined;
    setItemNotes: (productId: number, note?: string) => void;

    getCouponApplied: () => Coupon | undefined;
    applyCoupon: (couponCode: string) => Promise<applyCouponResponse>;
    removeCoupon: () => void;

    getSubTotal: () => number;
    getDeliveryFee: () => number;
    getDiscount: () => number;
    getSubTotalWithDiscount: () => number;
    getTotal: () => number;

    getPaymentIntent: () => Promise<string | null>;

    recalculate: () => Promise<void>;
};

const ShoppingCartContext = createContext({} as tShoppingCartContext);

type ShoppingCartProviderProps = {
    children: ReactNode;
};

export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
    const [cart, setCart] = useState<ShoppingCart>();

    const [couponApplied, setCouponApplied] = useState<Coupon>();

    const { store } = useStoreContext();

    useEffect(() => {
        async function getCart() {
            try {
                let cart = await GetShoppingCart();
                cart.items.forEach((item) => {
                    item.amount = function () {
                        return calculateItemAmount(
                            item.product.price,
                            item.quantity
                        );
                    };
                });

                setCouponApplied(cart.coupon);

                setCart(cart);
            } catch (error) {
                console.log(error);
                let cart = await CreateShoppingCart();
                setCart(cart);
            }
        }
        if (!cart) getCart();
    }, []);

    function setCustomer(customer: tCustomer) {
        if (cart) setCart({ ...cart, customer: customer });
    }
    function setAddress(address: tAddress) {
        if (cart) setCart({ ...cart, deliveryAddress: address });
    }

    function setDeliveryType(deliveryType: eDeliveryType) {
        if (cart) setCart({ ...cart, deliveryType: deliveryType });
    }

    function setPaymentMethod(paymentMethod: ePaymentMethod) {
        if (cart) setCart({ ...cart, paymentMethod: paymentMethod });
    }

    function setNotes(notes: string) {
        if (cart) setCart({ ...cart, comments: notes });
    }

    function getItemQuantity(productId: number) {
        if (!cart) return 0;
        return (
            cart.items.find((item) => item.product.id === productId)
                ?.quantity || 0
        );
    }

    async function increaseQuantity(productId: number, interval: number) {
        if (!cart) return;

        let product = store.products.find((x) => x.id === productId);
        if (!product) return;

        let currItem = cart.items.find((item) => item.product.id === productId);

        let quantity = 0;
        if (!currItem) quantity = interval;
        else quantity = currItem.quantity + interval;

        quantity = Math.round(quantity * 100) / 100;

        try {
            await AddOrUpdateShoppingCartItem(cart.id, productId, quantity);

            // Si es nuevo item, lo agrega al carrito
            if (!currItem)
                cart.items.push({
                    id: productId,
                    product,
                    quantity: interval,
                    amount(): number {
                        return calculateItemAmount(
                            this.product.price,
                            this.quantity
                        );
                    },
                });
            else currItem.quantity = quantity; // Actualiza la cantidad

            setCart({ ...cart, items: [...cart.items] });
        } catch (error) {
            console.log(error);
            // TODO: Handle error
            throw error;
        }
    }

    function calculateItemAmount(price: number, quantity: number) {
        return price * quantity;
    }

    async function decreaseQuantity(productId: number, interval: number) {
        if (!cart) return;

        let currItem = cart.items.find((item) => item.product.id === productId);
        if (!currItem) return;

        if (currItem.quantity === interval) {
            await removeFromCart(productId);
            return;
        }

        let quantity = currItem.quantity - interval;
        quantity = Math.round(quantity * 100) / 100;

        try {
            await AddOrUpdateShoppingCartItem(cart.id, productId, quantity);
            currItem.quantity = quantity;

            setCart({ ...cart, items: [...cart.items] });
        } catch (error) {
            console.log(error);
            // TODO: Handle error
            throw error;
        }
    }

    async function setItemNotes(productId: number, notes?: string) {
        if (!cart) return;

        let currItem = cart.items.find((item) => item.product.id === productId);
        if (!currItem) return;

        try {
            if (!notes) notes = "";

            await SetShoppingCartItemNotes(cart.id, productId, notes);
            currItem.notes = notes;
            setCart({ ...cart, items: [...cart.items] });
        } catch (error) {
            console.log(error);
            // TODO: Handle error
            throw error;
        }
    }

    async function removeFromCart(productId: number) {
        if (!cart) return;

        try {
            await RemoveShoppingCartItem(cart.id, productId);
            cart.items = cart.items.filter(
                (item) => item.product.id !== productId
            );
            setCart({ ...cart, items: [...cart.items] });
        } catch (error) {
            console.log(error);
            // TODO: Handle error
            throw error;
        }
    }

    async function emptyCart() {
        const newCart = await CreateShoppingCart();

        setCart({ ...newCart });
    }

    function getCartNumberOfItems() {
        if (!cart) return 0;
        return cart.items.length;
    }

    function getItemNotes(productId: number) {
        if (!cart) return;
        let currItem = cart.items.find((item) => item.product.id === productId);
        if (!currItem) return;

        return currItem.notes;
    }

    /* 
   Coupons
   */

    async function applyCoupon(
        couponCode: string
    ): Promise<applyCouponResponse> {
        if (!cart) return { success: false, message: "No hay carrito" };

        try {
            const response = await ApplyCoupon(cart.id, couponCode);
            if (!response.success)
                return { success: false, message: response.message };

            cart.coupon = response.coupon;
            setCart({ ...cart });

            setCouponApplied(response.coupon);
            return { success: true };
        } catch (error) {
            return { success: false, message: "Error al aplicar el cupón" };
        }
    }

    async function removeCoupon() {
        if (!cart) return;

        const response = await RemoveCoupon(cart.id);
        if (!response.success) throw new Error(response.message);

        setCouponApplied(undefined);
    }

    function getCouponApplied() {
        return couponApplied;
    }

    /* 
  Totals 
  */
    function getSubTotal() {
        if (!cart) return 0;
        return cart.items.reduce<number>((accumulator, current) => {
            return accumulator + current.amount();
        }, 0);
    }

    function getDeliveryFee() {
        if (!cart) return 0;

        if (cart.deliveryType === eDeliveryType.Pickup) return 0;

        if (getSubTotal() >= store.deliveryFreeShippingMinimumAmount) return 0;

        if (!isLocalDelivery()) return store.deliveryFee;

        return store.localDeliveryFee;
    }

    function isLocalDelivery() {
        if (!cart || !cart.deliveryAddress) return false;

        // If the zipcode is in the store.localDeliveryZones, return true
        const isLocal = store.localDeliveryZones.includes(
            cart.deliveryAddress.zipCode
        );

        console.log("delivery zip", cart.deliveryAddress.zipCode);
        console.log("isLocal", isLocal);
        return isLocal;
    }

    function getDiscount() {
        if (!cart?.coupon) return 0;
        //if (!couponApplied) return 0;

        if (getSubTotal() < cart.coupon.minimumPurchaseAmount) return 0;

        let discount = 0;
        if (cart.coupon.isPercentaje) {
            discount = (getSubTotal() * cart.coupon.amount) / 100;
        } else {
            if (cart.coupon.amount > getSubTotal()) discount = getSubTotal();
            else discount = cart.coupon.amount;
        }
        return discount;
    }

    function getSubTotalWithDiscount() {
        return getSubTotal() - getDiscount();
    }

    function getTotal() {
        return getSubTotal() - getDiscount() + getDeliveryFee();
    }

    /* Stripe */
    const [paymentIntent, setPaymentIntent] = useState<string | null>(null);
    async function getPaymentIntent() {
        if (!paymentIntent) {
            const p = await CreatePaymentIntent(cart!.id);
            setPaymentIntent(p);
            return p;
        }
        return paymentIntent;
    }

    if (!cart) return <div>Loading...</div>;
    return (
        <ShoppingCartContext.Provider
            value={{
                cart,
                getItemQuantity,
                increaseQuantity,
                decreaseQuantity,
                removeFromCart,
                emptyCart,
                getCouponApplied,
                applyCoupon,
                removeCoupon,
                getCartNumberOfItems,
                getItemNotes,
                setItemNotes,
                getSubTotal,
                getDeliveryFee,
                getDiscount,
                getSubTotalWithDiscount,
                getTotal,
                setPaymentMethod,
                setComments: setNotes,
                setAddress,
                setCustomer,
                setDeliveryType,
                getPaymentIntent,
                recalculate,
            }}
        >
            {children}
        </ShoppingCartContext.Provider>
    );

    async function recalculate() {
        try {
            let cart = await GetShoppingCart();

            for (let i = 0; i < cart.items.length; i++) {
                const item = cart.items[i];
                const product = store.products.find(
                    (x) => x.id === item.product.id
                );
                if (!product || product.disabled || product.outOfStock) {
                    cart.items.splice(i, 1);
                    continue;
                }
                if (product.price !== item.product.price) {
                    item.product = product;
                    await AddOrUpdateShoppingCartItem(
                        cart.id,
                        item.product.id,
                        item.quantity
                    );
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export function useShoppingCart() {
    return useContext(ShoppingCartContext);
}
