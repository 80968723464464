// Normalize a string to remove accents and make it lower case
export const NormalizeString = (string: string) =>
  string
    .toLocaleLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");

// Format a number with commas and two decimal places. optionally add a dollar sign
export function FormatCurrency(
  number: number,
  currency: boolean = false,
  blankIfZero: boolean = false
) {
  if (blankIfZero && number === 0) return "";

  let rValue = "";
  if (currency) rValue = "$";
  return rValue + commify(number.toFixed(2));
}

// Format a number with commas
function commify(n: string) {
  var parts = n.split(".");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return (
    numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "")
  );
}

// Joins two paths together, making sure there is only one slash between them
export const JoinUrl = (url: string, path: string) => {
  if (url.endsWith("/") && path.startsWith("/")) {
    return `${url}${path.substring(1)}`;
  } else if (!url.endsWith("/") && !path.startsWith("/")) {
    return `${url}/${path}`;
  } else {
    return `${url}${path}`;
  }
};

export const NewGuid = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
