import {
    faFacebook,
    faInstagram,
    faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import { useStoreContext } from "../../api/context/StoreContext";
import Logo from "../Logo";
import LanguageSwitcher from "../Common/LanguageSwitcher";
import { t } from "i18next";

interface FooterProps extends React.HTMLAttributes<HTMLDivElement> {}

const Footer: FunctionComponent<FooterProps> = (props) => {
    const { store } = useStoreContext();

    return (
        <div id="Footer" {...props}>
            <div id="Footer-contacto" className="ps-10">
                {store.contactInfo.homePageUrl && (
                    <a
                        title={t("footer_website")}
                        className="clear-link text-white"
                        href={store.contactInfo.homePageUrl}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <FontAwesomeIcon icon={faGlobe} />
                    </a>
                )}
                {store.contactInfo.email && (
                    <a
                        title={t("footer_email")}
                        className="clear-link text-white"
                        href={`mailto:${store.contactInfo.email}`}
                    >
                        <FontAwesomeIcon icon={faEnvelope} />
                    </a>
                )}
                {store.contactInfo.facebook && (
                    <a
                        title={t("footer_facebook")}
                        className="clear-link text-white"
                        href={store.contactInfo.facebook}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                )}
                {store.contactInfo.instagram && (
                    <a
                        title={t("footer_instagram")}
                        className="clear-link text-white"
                        href={store.contactInfo.instagram}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                )}
                {store.contactInfo.whatsApp && (
                    <a
                        title={t("footer_whatsapp")}
                        className="clear-link text-white"
                        href={`https://wa.me/${store.contactInfo.whatsApp}`}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                )}
            </div>
            <div
                id="Footer-platform"
                className="d-flex align-items-center justify-content-between"
            >
                <a href="https://www.prontonube.com">
                    <Logo height="30px" />
                </a>
                <LanguageSwitcher className="my-10" />
            </div>
        </div>
    );
};

export default Footer;
