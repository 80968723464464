import { Accordion, Alert } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import NavigateBackButton from "../../components/Navigation/NavigateBackButton";
import { useShoppingCart } from "../../api/context/ShoppingCartContext";
import { FormatCurrency } from "../../api/helpers/stringHelpers";

import { eDeliveryType, ePaymentMethod } from "../../api/models/cartModel";
import { useEffect, useState } from "react";
import {
    Elements,
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import { GetErrorMessage } from "../../api/helpers/apiHelper";
import {
    CreateOrder,
    GetStripeConfig,
} from "../../api/services/ShoppingCartServices";
import { useSession } from "../../api/context/SessionContext";
import { buildStoreFrontUrl } from "../../api/helpers/urlHelper";
import { t } from "i18next";
import { LoadingButton } from "../../components/Common/LoadingButton";
import CheckoutPaymentForm from "./CheckoutPaymentForm";

const CheckoutPaymentPage = () => {
    const navigate = useNavigate();
    const shoppingCart = useShoppingCart();

    const session = useSession();

    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState("");

    const location = useLocation();

    const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
    const [clientSecret, setClientSecret] = useState("");

    const cart = useShoppingCart();

    useEffect(() => {
        async function initStripe() {
            const config = await GetStripeConfig();

            const promise = await loadStripe(config.publishableKey, {
                stripeAccount: config.stripeConnectClientId,
            });
            setStripePromise(promise);

            const secret = await cart.getPaymentIntent();
            setClientSecret(!secret ? "" : secret);
        }
        initStripe();
        shoppingCart.cart.paymentMethod = ePaymentMethod.None;
    }, []);

    const continuar = async () => {
        if (shoppingCart.cart.paymentMethod === ePaymentMethod.None) {
            setErrorText(t("select_payment_method"));
            return;
        }

        try {
            setLoading(true);

            // Crea o actualiza la orden
            const createOrderResponse = await createOrder();
            if (!createOrderResponse) return;

            if (shoppingCart.cart.paymentMethod === ePaymentMethod.Cash) {
                navigate(
                    `../checkout-complete?orderId=${createOrderResponse.id}`
                );
            }
        } catch (error) {
            setErrorText(GetErrorMessage(error));
            navigate("checkout");
            return;
        } finally {
            setLoading(false);
        }
    };

    const createOrder = async () => {
        const response = await CreateOrder(
            shoppingCart,
            session.getLoggedUser()!
        );

        if (!response.success) {
            setErrorText(response.message);
            return null;
        }

        return response.order;
    };

    return (
        <div className="p-2">
            <div className="py-4 bg-white d-flex">
                <NavigateBackButton onClick={() => navigate(-1)} />
                <h2 className="m-0 ms-2">{t("payment")}</h2>
            </div>

            <section className="mt-2 mb-2">
                <Accordion defaultActiveKey="">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            {t("payment_amount")}:{" "}
                            {FormatCurrency(shoppingCart.getTotal(), true)}
                        </Accordion.Header>
                        <Accordion.Body>
                            <div id="CheckoutPay--orderSummary">
                                <div className="row">
                                    <div className="col">
                                        <NavLink to="../checkout">
                                            {t("products")}
                                        </NavLink>
                                    </div>
                                    <div className="col text-end">
                                        {FormatCurrency(
                                            shoppingCart.getSubTotal(),
                                            true
                                        )}
                                    </div>
                                </div>
                                {shoppingCart.cart.deliveryType ===
                                    eDeliveryType.Delivery && (
                                    <div className="row">
                                        <div className="col">
                                            {t("delivery_fee")}
                                        </div>
                                        <div className="col text-end">
                                            {FormatCurrency(
                                                shoppingCart.getDeliveryFee(),
                                                true
                                            )}
                                        </div>
                                    </div>
                                )}
                                {shoppingCart.getCouponApplied() && (
                                    <div className="row">
                                        <div className="col">
                                            {t("discount")}
                                        </div>
                                        <div className="col text-end text-danger">
                                            {FormatCurrency(
                                                shoppingCart.getDiscount(),
                                                true
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col">{t("total")}</div>
                                    <div className="col text-end">
                                        {FormatCurrency(
                                            shoppingCart.getTotal(),
                                            true
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </section>

            <section className="mt-2 mb-2">
                <h2>{t("payment")}</h2>
                <div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radioCard"
                            id="radioCard"
                            disabled={clientSecret === ""}
                            checked={
                                shoppingCart.cart.paymentMethod ===
                                ePaymentMethod.Card
                            }
                            onChange={(e) =>
                                shoppingCart.setPaymentMethod(
                                    ePaymentMethod.Card
                                )
                            }
                        />
                        <label className="form-check-label" htmlFor="radioCard">
                            {t("payment_credit_card")}
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radioCash"
                            id="radioCash"
                            checked={
                                shoppingCart.cart.paymentMethod ===
                                ePaymentMethod.Cash
                            }
                            onChange={(e) =>
                                shoppingCart.setPaymentMethod(
                                    ePaymentMethod.Cash
                                )
                            }
                        />
                        <label className="form-check-label" htmlFor="radioCash">
                            {t("payment_cash")}
                        </label>
                    </div>
                </div>
                <div className="d-grid">
                    {errorText && (
                        <Alert className="mt-4 mb-2 w-100" variant="danger">
                            {errorText}
                        </Alert>
                    )}
                </div>
                <div className="my-3">
                    {shoppingCart.cart.paymentMethod ===
                        ePaymentMethod.Card && (
                        <Elements
                            stripe={stripePromise}
                            options={{ clientSecret }}
                        >
                            <CheckoutPaymentForm
                                onSaveOrder={async () => {
                                    try {
                                        const createOrderResponse =
                                            await createOrder();
                                        if (createOrderResponse)
                                            return createOrderResponse.id;
                                    } catch (error) {
                                        setErrorText(GetErrorMessage(error));
                                    }
                                }}
                                onError={(error) => setErrorText(error)}
                            />
                        </Elements>
                    )}
                    {shoppingCart.cart.paymentMethod ===
                        ePaymentMethod.Cash && (
                        <LoadingButton
                            isLoading={loading}
                            onClick={() => continuar()}
                        >
                            {t("continue")}
                        </LoadingButton>
                    )}
                </div>
            </section>
        </div>
    );
};

export default CheckoutPaymentPage;
