export enum eProductTag {
  None = 0,
  New,
  Sale,
  Limited,
}
export type Product = {
  id: number;
  name: string;
  searchTerms?: string[];
  featured: boolean;
  tag: eProductTag;
  order: number;
  thumbUrl: string;
  regularPrice: number;
  price: number;
  unitOfMeasure: string;
  interval: number;
  productCategoryId: number;
  productSubCategoryId: number;
  disabled: boolean;
  outOfStock: boolean;
};
