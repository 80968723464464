import React from "react";

interface RibbonImageProps {
  imageUrl: string;
  ribbonText?: string;
}

const RibbonImage: React.FC<RibbonImageProps> = ({ imageUrl, ribbonText }) => {
  const showRibbon = !!ribbonText;
  const grayscaleFilter = showRibbon ? "grayscale(100%)" : "";
  return (
    <div className="ribbon-image-container">
      <img
        src={imageUrl}
        alt="ribbon"
        className="ribbon-image"
        style={{ filter: grayscaleFilter }}
      />
      {showRibbon && (
        <div className="ribbon">
          <span className="ribbon-text">{ribbonText}</span>
        </div>
      )}
    </div>
  );
};

export default RibbonImage;
