import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useShoppingCart } from "../../api/context/ShoppingCartContext";
import LoadingComponent from "../../components/Common/LoadingComponent";
import { GetErrorMessage } from "../../api/helpers/apiHelper";
import ErrorModal from "../../components/Common/ErrorModal";
import { GetOrder } from "../../api/services/OrderServices";
import { t } from "i18next";
import { ResetCart } from "../../api/services/ShoppingCartServices";

const CheckoutCompletePage = () => {
    const shoppingCart = useShoppingCart();
    const location = useLocation();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    const [order, setOrder] = useState<any>();

    useEffect(() => {
        const cargarOrden = async () => {
            const searchParams = new URLSearchParams(location.search);
            const orderId = searchParams.get("orderId");
            if (!orderId) {
                setLoading(false);
                return;
            }

            try {
                const orderResponse = await GetOrder(orderId);
                if (!orderResponse.success) {
                    console.log("No se pudo cargar la orden");
                    return;
                }
                setOrder(orderResponse.order);
            } catch (error) {
                setErrorMessage(GetErrorMessage(error));
                navigate("./");
            } finally {
                setLoading(false);
            }
        };

        localStorage.removeItem("cartId");

        shoppingCart.emptyCart();
        cargarOrden();
    }, []);

    if (loading) {
        if (errorMessage) return <ErrorModal errorMessage={errorMessage} />;
        else return <LoadingComponent />;
    }

    return (
        <div
            className="p-2 d-flex flex-column align-items-center justify-content-center"
            style={{ height: "85vh" }}
        >
            <p className="fs-1">
                ¡{t("thanks_for_your_order")}{" "}
                {order && <strong>{order.customer.name}</strong>}
                {" !"}
            </p>
            <p className="fs-2 text-center">
                {t("order_completed")}{" "}
                {order && (
                    <div>
                        <span>{t("with_order_number")}: </span>
                        <span className="text-primary">{order?.id}</span>
                    </div>
                )}
            </p>
            <p className="fs-3 text-center">
                {t("order_received")}{" "}
                {order && (
                    <div>
                        {t("we_ve_sent_you_an_email")}:
                        <span className="text-primary">
                            {" "}
                            {order?.customer.email}
                        </span>
                    </div>
                )}
            </p>
            <button
                className="btn btn-primary w-100 mt-4"
                onClick={() => {
                    ResetCart();
                    navigate("..");
                }}
            >
                {t("back_to_home")}
            </button>
        </div>
    );
};

export default CheckoutCompletePage;
