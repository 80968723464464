import { FunctionComponent, useEffect, useState } from "react";
import {
    GetCountries,
    GetStates,
    GetZipCodeInfo,
} from "../../api/services/AddressServices";
import { MapComponent } from "./MapComponent";
import { t } from "i18next";
import { useStoreContext } from "../../api/context/StoreContext";

export type tAddress = {
    street: string;
    outsideNumber: string;
    innerNumber: string;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    lat: number | null;
    lng: number | null;
};

interface AddressInputProps {
    address: tAddress;
    onChange: (address: tAddress) => void;
}

const AddressInput: FunctionComponent<AddressInputProps> = ({
    address,
    onChange,
}) => {
    const [neighborhoods, setNeighborhoods] = useState<string[]>([]);

    const { store } = useStoreContext();

    useEffect(() => {
        if (!address.country) {
            address.country = store.countryCode;
            onChange(address);
        }
    }, []);

    function useMyLocation() {
        if (!navigator.geolocation) return;

        //TODO: Show spinner during getting location

        navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
                let newAddress = { ...address };

                newAddress.lat = position.coords.latitude;
                newAddress.lng = position.coords.longitude;

                const geocoder = new google.maps.Geocoder();
                geocoder.geocode(
                    { location: { lat: newAddress.lat, lng: newAddress.lng } },
                    (results, status) => {
                        if (status === "OK" && results?.length! > 0) {
                            let r = results![0];
                            r.address_components.forEach((a) => {
                                let isRoute =
                                    a.types.findIndex((e) => e === "route") >=
                                    0;
                                if (isRoute) newAddress.street = a.long_name;
                                let isState =
                                    a.types.findIndex(
                                        (e) =>
                                            e === "administrative_area_level_1"
                                    ) >= 0;
                                if (isState) newAddress.state = a.long_name;
                                let isCountry =
                                    a.types.findIndex((e) => e === "country") >=
                                    0;
                                if (isCountry)
                                    newAddress.country = a.short_name;
                                let isNumber =
                                    a.types.findIndex(
                                        (e) => e === "street_number"
                                    ) >= 0;
                                if (isNumber)
                                    newAddress.outsideNumber = a.long_name;
                                let isLocality =
                                    a.types.findIndex(
                                        (e) => e === "locality"
                                    ) >= 0;
                                if (isLocality) newAddress.city = a.long_name;
                            });
                        }
                    }
                );
                onChange(newAddress);
            }
        );
    }

    function findMyLocation() {
        if (!address.street || !address.city || !address.state) return;

        //TODO: Spinner
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode(
            {
                address: `${address.street} ${address.outsideNumber}, ${address.city}, ${address.state}`,
            },
            (result, status) => {
                if (status === "OK") {
                    onChange({
                        ...address,
                        lat: result![0].geometry.location.lat(),
                        lng: result![0].geometry.location.lng(),
                    });
                }
            }
        );
    }

    return (
        <div>
            {/* Street */}
            <div className="form-floating mb-3">
                <input
                    type="text"
                    id="street"
                    value={address.street}
                    required
                    onChange={(e) =>
                        onChange({ ...address, street: e.currentTarget.value })
                    }
                    className="form-control"
                />
                <label htmlFor="street">{t("street")}</label>
            </div>

            {/* outsideNumber and innerNumber */}
            <div className="row mb-4">
                <div className="col">
                    <div className="form-floating">
                        <input
                            type="text"
                            id="outsideNumber"
                            value={address.outsideNumber}
                            onChange={(e) =>
                                onChange({
                                    ...address,
                                    outsideNumber: e.currentTarget.value,
                                })
                            }
                            className="form-control"
                        />
                        <label htmlFor="outsideNumber">
                            {t("outside_number")}
                        </label>
                    </div>
                </div>
                <div className="col">
                    <div className="form-floating">
                        <input
                            type="text"
                            id="innerNumber"
                            value={address.innerNumber}
                            onChange={(e) =>
                                onChange({
                                    ...address,
                                    innerNumber: e.currentTarget.value,
                                })
                            }
                            className="form-control"
                        />
                        <label htmlFor="innerNumber">{t("inner_number")}</label>
                    </div>
                </div>
            </div>
            {/* zipCode */}
            <div className="form-floating mb-3">
                <input
                    type="text"
                    id="zipCode"
                    value={address.zipCode}
                    required
                    onChange={(e) => {
                        let zipCode = e.currentTarget.value;
                        let zipInfo = GetZipCodeInfo(zipCode);
                        let newAddress = { ...address, zipCode: zipCode };
                        if (zipInfo) {
                            newAddress.city = zipInfo.city;
                            newAddress.state = zipInfo.state;
                            newAddress.neighborhood = zipInfo.neighborhood[0];
                            newAddress.city = zipInfo.city;
                            setNeighborhoods(zipInfo.neighborhood);
                        } else {
                            setNeighborhoods([]);
                        }

                        onChange(newAddress);
                    }}
                    className="form-control"
                />
                <label htmlFor="zipCode">{t("zip_code")}</label>
            </div>

            {/* neighborhood */}
            <div className="form-floating mb-3">
                <input
                    type="text"
                    id="neighborhood"
                    value={address.neighborhood}
                    list="neighborhoods"
                    required
                    onChange={(e) =>
                        onChange({
                            ...address,
                            neighborhood: e.currentTarget.value,
                        })
                    }
                    className="form-control"
                />
                <datalist id="neighborhoods">
                    console.log(neighborhoods);
                    {neighborhoods.map((x, i) => (
                        <option key={i} value={x} />
                    ))}
                </datalist>
                <label htmlFor="neighborhood">{t("neighborhood")}</label>
            </div>

            {/* country */}
            <div className="form-floating mb-3">
                <select
                    className="form-select"
                    id="country"
                    value={address.country}
                    required
                    onChange={(e) =>
                        onChange({ ...address, country: e.currentTarget.value })
                    }
                >
                    {GetCountries().map((x) => (
                        <option key={x.code} value={x.code}>
                            {x.name}
                        </option>
                    ))}
                </select>
                <label htmlFor="country">{t("country")}</label>
            </div>

            {/* state */}
            <div className="form-floating mb-3">
                <select
                    id="state"
                    value={address.state}
                    required
                    className="form-select"
                    onChange={(e) =>
                        onChange({ ...address, state: e.currentTarget.value })
                    }
                >
                    {GetStates(address.country).map((x) => (
                        <option key={x} value={x}>
                            {x}
                        </option>
                    ))}
                </select>
                <label htmlFor="state">{t("state")}</label>
            </div>

            {/* city */}
            <div className="form-floating mb-3">
                <input
                    type="text"
                    id="city"
                    value={address.city}
                    required
                    onChange={(e) =>
                        onChange({ ...address, city: e.currentTarget.value })
                    }
                    className="form-control"
                />
                <label htmlFor="city">{t("city")}</label>
            </div>

            {/* mapa */}
            <div className="input-group mb-3">
                <div className="form-control px-4">
                    <div className="row mb-2">
                        {t("set_your_location_on_the_map")}
                    </div>
                    <div className="row row mb-2">
                        <button
                            className="col me-2 btn btn-sm btn-success"
                            onClick={useMyLocation}
                        >
                            {t("use_my_current_location")}
                        </button>
                        <button
                            className="col btn btn-sm btn-primary"
                            onClick={findMyLocation}
                        >
                            {t("search_my_address")}
                        </button>
                    </div>

                    <MapComponent
                        markerLat={address.lat}
                        markerLng={address.lng}
                    />
                </div>
            </div>
        </div>
    );
};

export default AddressInput;
