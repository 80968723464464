const HeaderPlatform: React.FC = () => {
    return (
        <div style={{backgroundColor: "#ace5fa"}} >
            <div className="d-flex " >
                <img
                    src="/img/logo-nube.svg"
                    alt="Prontonube logo"
                    style={{width: "80px", height: "50px"}}
                />
                <img
                    src="/img/logo-title.svg"
                    alt="Prontonube logo"
                    style={{width: "80px", height: "50px"}}
                />
            </div>
        </div>
    )
}
export default HeaderPlatform;
