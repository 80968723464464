import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./en/translation.json";
import enAuthentication from "./en/authentication.json";
import enCheckout from "./en/checkout.json";

import esTranslation from "./es/translation.json";
import esAuthentication from "./es/authentication.json";
import esCheckout from "./es/checkout.json";

const browserLanguage = navigator.language.split(/[-_]/)[0]; // Get the browser language
export const language =
    browserLanguage === "en" || browserLanguage === "es"
        ? browserLanguage
        : process.env.REACT_APP_DefaultLanguage; // Default language set into Env if not supported

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                ...enTranslation,
                ...enAuthentication,
                ...enCheckout,
            },
        },
        es: {
            translation: {
                ...esTranslation,
                ...esAuthentication,
                ...esCheckout,
            },
        },
    },
    lng: language,
    fallbackLng: process.env.REACT_APP_DefaultLanguage,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
