import { JoinUrl } from "./stringHelpers";

export const buildStoreFrontUrl = (path: string, location: any) => {
    const baseUrl = process.env.REACT_APP_STOREFRONT_BASE_URL;
    if (!baseUrl) throw new Error("REACT_APP_STOREFRONT_BASE_URL not set");
    const url = new URL(baseUrl);
    const fullUrl = `${url.protocol}//${url.host}${location.pathname}`;
    if (path) return JoinUrl(fullUrl, path);
    return fullUrl;
};
