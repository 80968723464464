import { BuildUrl, CreateAxiosInstance } from "../helpers/apiHelper";

const axiosInstance = CreateAxiosInstance();

export const GetOrder = async (orderId: string) => {
    try {
        const url = BuildUrl(`orders/${orderId}`);
        const response = await axiosInstance.get(url);
        return response.data;
    } catch (error) {
        console.log("Error getting order " + orderId, error);
        throw error;
    }
};
